import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Rules = (props) => (
    <header id="rules" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <Link to="/"><span className="icon fa-book"></span></Link>
        </div>
        <div className="content">
            <div className="inner">
                <h1>New Haven Speculative Fiction Workshop</h1>
                <h3>Rules</h3>
                <p><strong>Deadline:</strong> Want feedback on a story? Great! The deadline to submit is the <br />
                Thursday before the workshop. You can (and should!) submit before <br />
                the deadline.</p>

                <p><strong>Repeat Rule:</strong> If we did your story last time, please wait to submit for <br />
                this round until the last day (Thursday).</p>

                <p><strong>Critiquing:</strong> We generally follow the <a href="https://en.wikipedia.org/w/index.php?title=Milford_Writer%27s_Workshop&oldid=844330971#Milford_rules">Milford rules</a>, but gently. Remember <br />
                what Hannibal Lecter says: "Discourtesy is unspeakably ugly to me."</p>

                <p><strong>What to submit:</strong> Speculative fiction! That means science fiction, <br />
                fantasy, weird, and loads else. Imagine your fellow critiquers are <br />
                editors and send them something as close to finished as you can. <br />
                First drafts are wonderful--but they're hard to workshop. Writing <br />
                is rewriting!</p>

                <p><strong>Harassment:</strong> Our goal is to create a safe and welcoming space for <br />
                all participants, and this means maintaining a level of mutual respect. <br />
                Definitely read the full text of our <a href="/policy">anti-harassment policy</a>.</p>

                <p><strong>Bottom line:</strong> Are you working towards getting published? <br />
                Are you serious about improving as a writer? <br />
                Are you not a jerk? <br /></p>

                <p>Let's workshop some stories!</p>

                <p><Link to="/">Go back to the homepage</Link></p>
            </div>
        </div>
        <nav>
            <ul>
                <li><Link to="/introduction">About</Link></li>
                <li><Link to="/members">Members</Link></li>
                <li><Link to="/rules">Rules</Link></li>
                <li><Link to="/contact">Contact</Link></li>
            </ul>
        </nav>
    </header>
)

Rules.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Rules




 
